import axios from "axios";
import { createAction } from "redux-actions";
import { get_service_endpoint } from "ServiceEndpoints";
import { AuthRoleIds } from "constants/Auth";
import { ALL_LF_ROLES } from "constants/Filters";
import { closeModal } from "./GlobalActions";
import { getLocofastUsers } from "./AuthActions";
import { flash } from './CommonActions';

import { ACTIVATION_STATUS, STATUS, VERIFICATION_STATUS, PAYMENT_BEFORE_DELIVERY, PAYMENT_NATURE } from "components/AccountSettings/Customers/constants";
import { MILLS_STATUS } from 'constants/Global';
import moment from "moment";

const GET_ORG_USERS_REQUEST = createAction("GET_ORG_USERS_REQUEST");
const GET_ORG_USERS_SUCCESS = createAction("GET_ORG_USERS_SUCCESS");
const GET_ORG_ACCOUNT_MANAGERS_SUCCESS = createAction(
  "GET_ORG_ACCOUNT_MANAGERS_SUCCESS"
);
const GET_ORG_USERS_FAILURE = createAction("GET_ORG_USERS_FAILURE");

const GET_CUSTOMERS_REQUEST = createAction("GET_CUSTOMERS_REQUEST");
const GET_CUSTOMERS_SUCCESS = createAction("GET_CUSTOMERS_SUCCESS");
const GET_CUSTOMER_BY_FID_SUCCESS = createAction("GET_CUSTOMER_BY_FID_SUCCESS");
const GET_CUSTOMER_BY_ID_SUCCESS = createAction("GET_CUSTOMER_BY_ID_SUCCESS");
const GET_CUSTOMERS_ID_FAILURE = createAction("GET_CUSTOMERS_ID_FAILURE");
const GET_CUSTOMERS_ID_REQUEST = createAction("GET_CUSTOMERS_ID_REQUEST");
const GET_CUSTOMERS_FAILURE = createAction("GET_CUSTOMERS_FAILURE");
const GET_VPA_SUCCESS = createAction("GET_VPA_SUCCESS");
const GET_VPA_FAILURE = createAction("GET_VPA_FAILURE");
const ADD_VPA_REQUEST = createAction("ADD_VPA_REQUEST");
const ADD_VPA_SUCCESS = createAction("ADD_VPA_SUCCESS");
const ADD_VPA_FAILURE = createAction("ADD_VPA_FAILURE");
const GET_MILLS_REQUEST = createAction("GET_MILLS_REQUEST");
const GET_MILLS_SUCCESS = createAction("GET_MILLS_SUCCESS");
const GET_MILLS_FAILURE = createAction("GET_MILLS_FAILURE");
const UPDATE_GST_REQUEST = createAction("UPDATE_GST_REQUEST");
const UPDATE_GST_SUCCESS = createAction("UPDATE_GST_SUCCESS");
const UPDATE_GST_FAILURE = createAction("UPDATE_GST_FAILURE");
const UPDATE_MSME_REQUEST = createAction("UPDATE_MSME_REQUEST");
const UPDATE_MSME_SUCCESS = createAction("UPDATE_MSME_SUCCESS");
const UPDATE_MSME_FAILURE = createAction("UPDATE_MSME_FAILURE");

const GET_MILL_DETAILS_REQUEST = createAction("GET_MILL_DETAILS_REQUEST");
const GET_MILL_DETAILS_SUCCESS = createAction("GET_MILL_DETAILS_SUCCESS");
const GET_MILL_DETAILS_FAILURE = createAction("GET_MILL_DETAILS_FAILURE");
const UPDATE_BASIC_INFO_REQUEST = createAction("UPDATE_BASIC_INFO_REQUEST");
const UPDATE_BASIC_INFO_SUCCESS = createAction("UPDATE_BASIC_INFO_SUCCESS");
const UPDATE_BASIC_INFO_FAILURE = createAction("UPDATE_BASIC_INFO_FAILURE");
const GET_LOGI_PARTNERS_REQUEST = createAction("GET_LOGI_PARTNERS_REQUEST");
const GET_LOGI_PARTNERS_SUCCESS = createAction("GET_LOGI_PARTNERS_SUCCESS");
const GET_LOGI_PARTNERS_FAILURE = createAction("GET_LOGI_PARTNERS_FAILURE");

const GET_RUNNING_QUALITIES_REQUEST = createAction("GET_RUNNING_QUALITIES_REQUEST");
const GET_RUNNING_QUALITIES_SUCCESS = createAction("GET_RUNNING_QUALITIES_SUCCESS");
const GET_RUNNING_QUALITIES_FAILURE = createAction("GET_RUNNING_QUALITIES_FAILURE");

const ADD_RUNNING_QUALITIES_REQUEST = createAction("ADD_RUNNING_QUALITIES_REQUEST");
const ADD_RUNNING_QUALITIES_SUCCESS = createAction("ADD_RUNNING_QUALITIES_SUCCESS");
const ADD_RUNNING_QUALITIES_FAILURE = createAction("ADD_RUNNING_QUALITIES_FAILURE");

const GET_RUNNING_QUALITIES_FILTERS_REQUEST = createAction("GET_RUNNING_QUALITIES_FILTERS_REQUEST");
const GET_RUNNING_QUALITIES_FILTERS_SUCCESS = createAction("GET_RUNNING_QUALITIES_FILTERS_SUCCESS");
const GET_RUNNING_QUALITIES_FILTERS_FAILURE = createAction("GET_RUNNING_QUALITIES_FILTERS_FAILURE");

const GET_ACCOUNT_MANAGERS_REQUEST = createAction(
  "GET_ACCOUNT_MANAGERS_REQUEST"
);
const GET_ACCOUNT_MANAGERS_SUCCESS = createAction(
  "GET_ACCOUNT_MANAGERS_SUCCESS"
);
const GET_ACCOUNT_MANAGERS_FAILURE = createAction(
  "GET_ACCOUNT_MANAGERS_FAILURE"
);
const GET_SUPPLIER_MANAGERS_REQUEST = createAction(
  "GET_SUPPLIER_MANAGERS_REQUEST"
);
const GET_SUPPLIER_MANAGERS_SUCCESS = createAction(
  "GET_SUPPLIER_MANAGERS_SUCCESS"
);
const GET_SUPPLIER_MANAGERS_FAILURE = createAction(
  "GET_SUPPLIER_MANAGERS_FAILURE"
);
const GET_PRODUCT_DEVELOPERS_REQUEST = createAction(
  "GET_PRODUCT_DEVELOPERS_REQUEST"
);
const GET_PRODUCT_DEVELOPERS_SUCCESS = createAction(
  "GET_PRODUCT_DEVELOPERS_SUCCESS"
);
const GET_PRODUCT_DEVELOPERS_FAILURE = createAction(
  "GET_PRODUCT_DEVELOPERS_FAILURE"
);
const GET_CURRENCY_RATE_REQUEST = createAction("GET_CURRENCY_RATE_REQUEST")
const GET_CURRENCY_RATE_SUCCESS = createAction("GET_CURRENCY_RATE_SUCCESS")
const GET_CURRENCY_RATE_FAILURE = createAction("GET_CURRENCY_RATE_FAILURE")

const UPDATE_CURRENCY_RATE = createAction("UPDATE_CURRENCY_RATE")
const UPDATE_CURRENCY_RATE_LOADING = createAction("UPDATE_CURRENCY_RATE_LOADING")

const ASSIGN_USERS_TO_ORG_REQUEST = createAction("ASSIGN_USERS_TO_ORG_REQUEST");
const ASSIGN_USERS_TO_ORG_SUCCESS = createAction("ASSIGN_USERS_TO_ORG_SUCCESS");
const ASSIGN_USERS_TO_ORG_FAILURE = createAction("ASSIGN_USERS_TO_ORG_FAILURE");

const ASSIGN_USERS_TO_TEAM_REQUEST = createAction(
  "ASSIGN_USERS_TO_TEAM_REQUEST"
);
const ASSIGN_USERS_TO_TEAM_SUCCESS = createAction(
  "ASSIGN_USERS_TO_TEAM_SUCCESS"
);
const ASSIGN_USERS_TO_TEAM_FAILURE = createAction("ASSIGN_USERS_TO_TEAM_FAIL");

export const CREATE_CUSTOMER = createAction("CREATE_CUSTOMER");
export const UPDATE_CUSTOMER = createAction("UPDATE_CUSTOMER");

export const VERIFY_ROLE_CHANGE = createAction("VERIFY_ROLE_CHANGE");
export const CHANGE_ROLE = createAction("CHANGE_ROLE");
export const CHANGE_ROLE_LOADING = createAction("CHANGE_ROLE_LOADING");

export const UPDATE_SUPPLIER_MANAGER = createAction("UPDATE_SUPPLIER_MANAGER");
export const UPDATE_SUPPLIER_MANAGER_REQUEST = createAction("UPDATE_SUPPLIER_MANAGER_REQUEST");
export const UPDATE_SUPPLIER_MANAGER_FAILURE = createAction("UPDATE_SUPPLIER_MANAGER_FAILURE");
export const UPDATE_ACCOUNT_MANAGER = createAction("UPDATE_ACCOUNT_MANAGER");
export const UPDATE_ACCOUNT_MANAGER_REQUEST = createAction(
  "UPDATE_ACCOUNT_MANAGER_REQUEST"
);
export const UPDATE_ACCOUNT_MANAGER_FAILURE = createAction(
  "UPDATE_ACCOUNT_MANAGER_FAILURE"
);
export const DEACTIVATE_CUSTOMER_REQUEST = createAction(
  "DEACTIVATE_CUSTOMER_REQUEST"
);
export const DEACTIVATE_CUSTOMER_SUCCESS = createAction(
  "DEACTIVATE_CUSTOMER_SUCCESS"
);
export const DEACTIVATE_CUSTOMER_FAILURE = createAction(
  "DEACTIVATE_CUSTOMER_FAILURE"
);
export const UPDATE_SEARCH_VALUE = createAction("UPDATE_SEARCH_VALUE");
export const UPDATE_FILTER_VALUE = createAction("UPDATE_FILTER_VALUE");

const epUsers = get_service_endpoint("auth");
const fabric = get_service_endpoint("fabric");
const finance= get_service_endpoint("finance");
const runningQualitiesApi = get_service_endpoint("runningQualities");

export const getOrgUsers =
  ({ orgId }) =>
  (dispatch) => {
    dispatch(GET_ORG_USERS_REQUEST());

    return axios
      .get(epUsers + "/user/assignedUsers", {
        params: {
          locofastRootId: orgId,
          assignedUsers: 1,
          relatedUsers: 1,
        },
      })
      .then((res) => {
        dispatch(
          GET_ORG_USERS_SUCCESS({
            orgId,
            data: res.data.resp.relatedUsers,
          })
        );
        dispatch(
          GET_ORG_ACCOUNT_MANAGERS_SUCCESS({
            orgId,
            data: res.data.resp.assignedUsers,
          })
        );
      })
      .catch(() => dispatch(GET_ORG_USERS_FAILURE()));
  };

export const getAccountManagers = () => (dispatch) => {
  dispatch(GET_ACCOUNT_MANAGERS_REQUEST());

  return axios
    .get(epUsers + "/users", {
      params: {
        role: AuthRoleIds.ACCOUNT_MANAGER,
      },
    })
    .then((res) =>
      dispatch(GET_ACCOUNT_MANAGERS_SUCCESS(Object.values(res.data)))
    )
    .catch(() => dispatch(GET_ACCOUNT_MANAGERS_FAILURE()));
};
export const getSupplierManagers = () => (dispatch) => {
  dispatch(GET_SUPPLIER_MANAGERS_REQUEST());
  return axios
    .get(epUsers + "/users", {
      params: {
        role: AuthRoleIds.SUPPLIER_MANAGER,
      },
    })
    .then((res) =>
      dispatch(GET_SUPPLIER_MANAGERS_SUCCESS(Object.values(res.data)))
    )
    .catch(() => dispatch(GET_SUPPLIER_MANAGERS_FAILURE()));
};

export const getCurrencyRate = () => (dispatch) => {
  dispatch(GET_CURRENCY_RATE_REQUEST());

  return axios
    .get(`${fabric}/fabrics/currency/conversion-factor`)
    .then((res) => {
      dispatch(GET_CURRENCY_RATE_SUCCESS(res.data));
    })
  .catch(() => dispatch(GET_CURRENCY_RATE_FAILURE()));
};

export const updateCurrency = ({currencyId,valueInInr}) => (dispatch) => {
  dispatch(UPDATE_CURRENCY_RATE_LOADING(true));

  return axios
    .patch(`${fabric}/fabrics/currency/conversion-factor`, { currencyId, valueInInr })
    .then((res) => {
      dispatch(flash({ message: res.data.data.message }));
      dispatch(UPDATE_CURRENCY_RATE(res.data));
      dispatch(UPDATE_CURRENCY_RATE_LOADING(false));
    })
    .catch(() => {
      dispatch(UPDATE_CURRENCY_RATE_LOADING(false));
    });
};

export const assignUsersToOrg =
  ({ orgId, userIds }) =>
  (dispatch) => {
    dispatch(ASSIGN_USERS_TO_ORG_REQUEST());

    return axios
      .put(epUsers + `/org/${orgId}/assign/users`, {
        userIds: Array.from(new Set(userIds)), // get the unique userIds
      })
      .then((res) => {
        dispatch(ASSIGN_USERS_TO_ORG_SUCCESS({ orgId, userIds }));
        dispatch(closeModal());
      })
      .catch(() => dispatch(ASSIGN_USERS_TO_ORG_FAILURE()));
  };

export const assignUsersToTeam =
  ({ managerId, userIds }) =>
  (dispatch) => {
    dispatch(ASSIGN_USERS_TO_TEAM_REQUEST());

    return axios
      .put(epUsers + `/users/${managerId}/assign/users`, {
        userIds: Array.from(new Set(userIds)), // get the unique userIds
      })
      .then((res) => {
        dispatch(ASSIGN_USERS_TO_TEAM_SUCCESS({ managerId, userIds }));
        dispatch(getLocofastUsers([ALL_LF_ROLES]));
        dispatch(closeModal());
      })
      .catch(() => dispatch(ASSIGN_USERS_TO_TEAM_FAILURE()));
  };

export const getCustomerById =
  ({ id }) =>
  (dispatch) => {
    dispatch(GET_CUSTOMERS_REQUEST());

    return axios
      .get(epUsers + `/customer/${id}`)
      .then((res) => dispatch(GET_CUSTOMER_BY_ID_SUCCESS(res.data)))
      .catch(() => dispatch(GET_CUSTOMERS_FAILURE()));
  };

export const getCustomerByIdFabric =
  ({ id }) =>
  (dispatch) => {
    dispatch(GET_CUSTOMERS_ID_REQUEST());
    return axios
      .get(fabric + `/customer/admin/${id}`)
      .then((res) => dispatch(GET_CUSTOMER_BY_FID_SUCCESS(res.data)))
      .catch(() => dispatch(GET_CUSTOMERS_ID_FAILURE()));
  };
  export const vpaDetails = ({id}) => (dispatch) =>{
    return axios
    .get(finance + `/payment/vpa-details?orgId=${id}/`)
    .then((res) => dispatch(GET_VPA_SUCCESS(res.data)))
    .catch(() => dispatch(GET_VPA_FAILURE()));
  };
  export const generateVPA =
  (orgId) =>
  (dispatch) => {
    dispatch(ADD_VPA_REQUEST());
    return axios
      .post(`${finance}/payment/vpa/create`,{"customerId":orgId})
      .then((res) => {
        dispatch(ADD_VPA_SUCCESS());
        dispatch(flash(({ message: 'VPA generated successfully' })));
        dispatch(vpaDetails({id: orgId}));
        return res.data;
      })
      .catch((err) => {
        dispatch(ADD_VPA_FAILURE());
        dispatch(flash({ message: err.response.data.data.message }));
      });
  };
  export const refreshGST =
    (orgId) =>
    (dispatch) => {
      dispatch(UPDATE_GST_REQUEST());
      return axios
        .patch(`${fabric}/customer/refresh-gst`,{"orgId":orgId})
        .then((res) => {
          dispatch(UPDATE_GST_SUCCESS());
          dispatch(flash({ message: res.data.data.message }));
          dispatch(getCustomerByIdFabric({id: orgId}));
          return res.data;
        })
        .catch((err) => {
          dispatch(UPDATE_GST_FAILURE());
          dispatch(flash({ message: err.response.data.data.message }));
        });
    };
    export const updateMSMEStatus = 
    (orgId) =>
    (dispatch) => {
      dispatch(UPDATE_MSME_REQUEST());
      return axios
        .patch(`${fabric}/customer/admin/refresh-msme-details`, {"customerId": orgId})
        .then((res) => {
          dispatch(UPDATE_MSME_SUCCESS());
          dispatch(flash({ message: 'MSME marked not available' }));
          dispatch(getCustomerByIdFabric({id: orgId}));
          return res.data;
        })
        .catch((err) => {
          dispatch(UPDATE_MSME_FAILURE());
          dispatch(flash({ message: err.response.data.data.message }));
        });
    };
export const updateFilter = (params) => (dispatch) => {
  dispatch(UPDATE_FILTER_VALUE(params));
};
export const updateSearch = (params) => (dispatch) => {
  dispatch(UPDATE_SEARCH_VALUE(params));
}
export const getCustomers = (params) => (dispatch) => {
  dispatch(GET_CUSTOMERS_REQUEST());
  const transformedData = transformFilter(params);
  return getOrgs({ ...transformedData, type: 3, withCount: 1 })
    .then((res) => dispatch(GET_CUSTOMERS_SUCCESS(res.data)))
    .catch(() => dispatch(GET_CUSTOMERS_FAILURE()));
};
const transformFilter = (params) => {
  const { country, status, accountManagerIds, searchText } = params;
  const filterObj = {};
  if(searchText){
    filterObj.searchText = searchText;
  }
  if (country) {
    filterObj.country = [country];
  }
  if(status && status === STATUS.verified){
      filterObj.isVerified = VERIFICATION_STATUS.verified;
  }
  if(status && status === STATUS.deactivated){
      filterObj.activationStatus = ACTIVATION_STATUS.deactivated;
  }
  if (accountManagerIds && accountManagerIds.length > 0) {
    filterObj.accountManagerIds = accountManagerIds;
  }
  return filterObj; 
}
const getOrgs = (params) => {
  return axios.get(`${fabric}/customer/admin/view`, { params });
};

export const verifyRoleChange = (params) => (dispatch) => {
  return axios
    .get(`${fabric}/users/verify-action`, { params })
    .then((res) => dispatch(VERIFY_ROLE_CHANGE(res.data.data)))
    .catch((err) => console.log("verify err", err));
};

export const changeRole = (params) => (dispatch) => {
  dispatch(CHANGE_ROLE_LOADING(true));

  return axios
    .patch(`${fabric}/users/action`, params)
    .then((res) => {
      dispatch(CHANGE_ROLE(res.data));
      dispatch(CHANGE_ROLE_LOADING(false));
    })
    .catch(() => {
      dispatch(CHANGE_ROLE_LOADING(false));
    });
};
export const updateAccountManager = (params) => (dispatch) => {
  dispatch(UPDATE_ACCOUNT_MANAGER_REQUEST());
  return axios
    .patch(`${fabric}/customer/admin/action`, params)
    .then((res) => {
      dispatch(UPDATE_ACCOUNT_MANAGER(res.data));
      window.location.reload();
    })
    .catch(() => dispatch(UPDATE_ACCOUNT_MANAGER_FAILURE()));
};

export const updateAllocatedSM = (params) => (dispatch) => {
  dispatch(UPDATE_SUPPLIER_MANAGER_REQUEST());
  return axios
    .patch(`${fabric}/suppliers/${params.supplierId}/allocate-sm`, params)
    .then((res) => {
      dispatch(UPDATE_SUPPLIER_MANAGER(res.data.data));
      dispatch(getMillDetails(params.supplierId));
    })
    .catch(() => dispatch(UPDATE_SUPPLIER_MANAGER_FAILURE()));
};

// Mills Actions
export const getMills = ({limit = 10, offset=0, search, ...params}) => (dispatch) => {
  dispatch(GET_MILLS_REQUEST());
  return getMillsFunc(limit, offset, search, params)
    .then((res) => dispatch(GET_MILLS_SUCCESS(res.data)))
    .catch((err) => dispatch(GET_MILLS_FAILURE()));
};

const getMillsFunc = (limit, offset, search = '', restProps) => {
  const params = { sortBy: 'name', sortOrder: 'asc' };
  const { status, fabric, services, category, supplierManagerId } = restProps;
  if (search) {
    params.searchText = search;
  }
  if (status && status === STATUS.verified) {
    params.isVerified = VERIFICATION_STATUS.verified;
  }
  if (status && status === STATUS.unverified) {
    params.isVerified = VERIFICATION_STATUS.unverified;
  }
  if (status && status === STATUS.deactivated) {
    params.isDeactivated = VERIFICATION_STATUS.verified;
  }
  if (fabric) {
    if (fabric.length === 2) {
      params.fabricTypeIds = JSON.stringify(['3']);
    } else if (fabric.length === 1) {
      params.fabricTypeIds = JSON.stringify(fabric);
    }
  }
  if (services && services.length > 0) {
    params.productionType = services;
  }
  if (category && category.length > 0) {
    params.fabricCategoryIds = category;
  }
  if (supplierManagerId && supplierManagerId.length > 0) {
    params.supplierManagerId = JSON.stringify(supplierManagerId);
  }
  params.offset = offset;
  params.limit = limit;

  return axios.get(`${process.env.REACT_APP_API_URL}/apifabric/suppliers/search`, {
    params,
    isSession: restProps?.isSession,
  });
};

export const getMillDetails = (id) => (dispatch) => {
  dispatch(GET_MILL_DETAILS_REQUEST());

  return getMillDetailsFunc(id)
    .then((res) => dispatch(GET_MILL_DETAILS_SUCCESS(res.data)))
    .catch((err) => dispatch(GET_MILL_DETAILS_FAILURE(err)));
};

const getMillDetailsFunc = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/apifabric/suppliers/${id}`);
};

export const updateMillDetails = ({formData, supplierId}) => (dispatch) => {
  return axios.patch(`${process.env.REACT_APP_API_URL}/apifabric/suppliers`, formData)
    .then(res => {
      dispatch(flash({ message: 'Mill details updated' }));
      dispatch(getMillDetails(supplierId))
      return res.data;
    })
    .catch(err => {
      dispatch(
        flash({
          message: err.response.data.data.message,
        })
      )
    })
}

export const changeMillStatus = ({supplierId, status}) => (dispatch) => {
  return axios.patch(`${process.env.REACT_APP_API_URL}/apifabric/suppliers/${supplierId}/online-status`, {supplierId, status})
    .then(res => {
      dispatch(flash({ message: 'Mill Status updated' }));
      dispatch(getMillDetails(supplierId));
      return res.data;
    })
    .catch(err => {
      dispatch(
        flash({
          message: "Could not update Mill status"
        })
      )
    })
}

export const changeDirectInvoiceStatus = ({supplierId, params, invoiceStatus}) => (dispatch) => {
  return axios.patch(`${process.env.REACT_APP_API_URL}/apifabric/suppliers/${supplierId}/direct-invoicing`, params)
  .then(res => {
    dispatch(flash({ message: `Commission slab ${invoiceStatus ? 'updated' : 'added'}!` }));
    dispatch(getMillDetails(supplierId));
    return res.data;
  })
  .catch(err => {
    dispatch(
      flash({
        message: "Could not update Direct Invoice status"
      })
    )
  })
}

export const deactivateMill = ({supplierId, newStatus: status, reason}) => (dispatch) => {
  return axios.patch(`${process.env.REACT_APP_API_URL}/apifabric/users/user-action`, {supplierId, actionType: status, deactivationReason: reason})
    .then(res => {
      dispatch(flash({ message: `Mill ${status === MILLS_STATUS.deactivate ? 'deactivated' : 'activated'}` }));
      dispatch(getMillDetails(supplierId));
      return res.data;
    })
}
export const deactivateCustomer = (params) => (dispatch) => {
  dispatch(DEACTIVATE_CUSTOMER_REQUEST());
  return axios
    .patch(`${fabric}/customer/admin/action`, params)
    .then((res) => {
      dispatch(DEACTIVATE_CUSTOMER_SUCCESS(res.data));
      window.location.reload();
    })
    .catch(() => dispatch(DEACTIVATE_CUSTOMER_FAILURE()));
};

export const changeLCVStatus = ({supplierId, isCertified}) => (dispatch) => {
  return axios.patch(`${process.env.REACT_APP_API_URL}/apifabric/suppliers/${supplierId}/certification-status`, {supplierId, isCertified})
    .then(res => {
      dispatch(flash({ message: 'Mill Status updated' }));
      dispatch(getMillDetails(supplierId));
      return res.data;
    })
    .catch(err => {
      dispatch(
        flash({
          message: "Could not update Mill status"
        })
      )
    })
}

export const updateBasicInfo =
  ({ id, params }) =>
  (dispatch) => {
    dispatch(UPDATE_BASIC_INFO_REQUEST());
    const transformedData = transformBasicInfo(params);
    return axios
      .patch(`${fabric}/customer/admin/edit/${id}`, transformedData)
      .then((res) => {
        dispatch(UPDATE_BASIC_INFO_SUCCESS(res.data));
        window.location.reload();
      })
      .catch(() => dispatch(UPDATE_BASIC_INFO_FAILURE()));
  };

const transformBasicInfo = (params) => {
  const formData = new FormData();

  formData.append("email", params?.email);
  formData.append("phone", params?.phoneNumber);
  if (params?.lastName) formData.append("lastName", params?.lastName);
  formData.append("firstName", params?.firstName);
  formData.append("designation", params?.designation);
  formData.append("webUrl", params?.website);
  formData.append("natureOfBusiness", params?.natureOfBusiness);
  if (+params?.isInternational === 0) {
    formData.append("gstCertificate", params?.gstCertificate);
  }
  if (+params?.isInternational === 1) {
    formData.append("uniqueTaxDocId", params?.uniqueTaxDocId);
    formData.append("uniqueTaxDocName", params?.uniqueTaxDocName);
    formData.append("uniqueTaxIdCertificate", params?.uniqueTaxIdCertificate);
  }
  return formData;
};

export const updateAnnualTurnover =
  ({ id, params, gstCertificate }) =>
  (dispatch) => {
    dispatch(UPDATE_BASIC_INFO_REQUEST());
    const transformedData = transformAnnualTurnover(params, gstCertificate);
    return axios
      .patch(`${fabric}/customer/admin/edit/${id}`, transformedData)
      .then((res) => {
        dispatch(UPDATE_BASIC_INFO_SUCCESS(res.data));
        window.location.reload();
      })
      .catch(() => dispatch(UPDATE_BASIC_INFO_FAILURE()));
  };

const transformAnnualTurnover = (params, gstCertificate) => {
  const formData = new FormData();
  if(params?.annualTurnover){
  formData.append("annualTurnover", params?.annualTurnover);
  }
  formData.append("advancePercentage", params?.advanceRequired);
  if(params?.paymentNature === PAYMENT_NATURE.CREDIT){
  formData.append("creditPeriod", JSON.stringify(params?.creditPeriod));
  } else {
    formData.append("creditPeriod", JSON.stringify(['0']));
  }
  formData.append("creditNature", params?.creditNature);

  if (params?.customerAgreement)
    formData.append("customerAgreement", params?.customerAgreement);
  if (params?.annualTurnoverDocs)
    formData.append("annualTurnoverDocs", params?.annualTurnoverDocs);
  if(params?.annualTurnoverDate){
    formData.append("annualTurnoverDate", moment(params?.annualTurnoverDate));
  }
  if (gstCertificate) {
    formData.append("gstCertificate", gstCertificate);
  }
  return formData;
};

export const getLogisticPartners = () => (dispatch) => {
  dispatch(GET_LOGI_PARTNERS_REQUEST());

  return getPartners()
    .then((res) => dispatch(GET_LOGI_PARTNERS_SUCCESS(res.data)))
    .catch(() => dispatch(GET_LOGI_PARTNERS_FAILURE()));
};

const getPartners = () => {
  return axios.get(fabric + "/courier-partners", {});
};

export const getRunningQualities = ({limit = 10, page = 1, filters = {}}) => (dispatch) => {
  dispatch(GET_RUNNING_QUALITIES_REQUEST())

  return axios.get(`${runningQualitiesApi}`, {params: {limit, pageNumber: page, ...filters}})
    .then(res => dispatch(GET_RUNNING_QUALITIES_SUCCESS(res.data)))
    .catch(err => dispatch(GET_RUNNING_QUALITIES_FAILURE()))
}

export const getRunningQualitiesFilters = () => (dispatch) => {
  dispatch(GET_RUNNING_QUALITIES_FILTERS_REQUEST())

  return axios.get(`${runningQualitiesApi}/filters`)
    .then(res => dispatch(GET_RUNNING_QUALITIES_FILTERS_SUCCESS(res.data)))
    .catch(err => dispatch(GET_RUNNING_QUALITIES_FILTERS_FAILURE()))
}

export const addRunningQualities = (payload) => (dispatch) => {
  dispatch(ADD_RUNNING_QUALITIES_REQUEST())

  const formData = new FormData()
  formData.append('file', payload.file)
  formData.append('supplierId', payload.supplierId)
  formData.append('supplierName', payload.supplierName)

  return axios.post(`${runningQualitiesApi}`, formData)
    .then((res) => dispatch(ADD_RUNNING_QUALITIES_SUCCESS(res)))
    .catch((err) => dispatch(ADD_RUNNING_QUALITIES_FAILURE(err)))
}