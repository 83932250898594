import { get_service_endpoint } from 'ServiceEndpoints';
import { flash } from './CommonActions';
import axios from 'axios';
import { createAction } from 'redux-actions';
const GET_INVENTORY_DATA = createAction('GET_INVENTORY_DATA');
const GET_INVENTORY_BALE_DATA = createAction('GET_INVENTORY_BALE_DATA');

const fabric = get_service_endpoint('fabric');


export const createNewSKU = (requestData) => async (dispatch) => {
  try {
    const res = await axios.post(`${fabric}/inventory`, requestData, { isSession: true });
    if (res.status == 204) {
      dispatch(flash({ message: 'SKU created successfully' }));
    }
    return res.data;
  } catch (err) {
    dispatch(
      flash({
        message: err.response.data.message,
      }),
    );
  }
};

export const getInventoryData = (params) => async (dispatch) => {
  try {
    const res = await axios.get(`${fabric}/inventory`, { params, isSession: true });
    dispatch(GET_INVENTORY_DATA(res.data.data));
  } catch (err) {
    dispatch(
      flash({
        message: err.msg,
      }),
    );
  }
};

export const getInventoryBaleData = ({productId, warehouseId, params}) => async (dispatch) => {
  try {
    const res = await axios.get(`${fabric}/inventory-bale/${productId}/${warehouseId}`, { params, isSession: true  });
    dispatch(GET_INVENTORY_BALE_DATA(res.data.data));
  } catch (err) {
    dispatch(
      flash({
        message: err.msg,
      }),
    );
  }
};

export const getWarehouseIdData = () => async (dispatch) => {
  try {
    const res = await axios.get(`${fabric}/inventory/warehouse`, { isSession: true });
    return res;
  } catch (err) {
    dispatch(
      flash({
        message: err.msg,
      }),
    );
  }
};
export const uploadInventory = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${fabric}/inventory-bale/upload`, data, { isSession: true });
    if (res.status == 204) {
      dispatch(flash({ message:'Inventory uploaded successfully' }));
    }
    return res.data;
  } catch (err) {
    dispatch(
      flash({
        message: err.response.data.message,
      }),
    );
  }
};

export const updateBaleData = ({baleId, formData}) => async (dispatch) => {
  try {
    const res = await axios.patch(`${fabric}/inventory-bale/${baleId}`, formData, { isSession: true });
    if (res.status == 204) {
      dispatch(flash({ message: 'Bale updated successfully' }));
    }
  } catch (err) {
    dispatch(
      flash({
        message: err.msg,
      }),
    );
  }
};