import { __computeConstructionAndThreadCount } from 'utilites/utils';
import { LOOM_TYPES_KEY } from '../TopQualities/config';
import editIcon from '../../../assets/images/image/ic-edit-blue.svg';
import trashIcon from '../../../assets/images/image/ic-trash.svg';
import infoIcon from '../../../assets/images/action/ic-info-blue.svg';
import certifiedTick from '../../../assets/CertifiedTick.svg';
import Chevron from 'assets/chevron_bottom.svg';
import style from './styles.module.scss';
import history from 'utilites/history';
import StatusDropdown from './EditInventory/StatusDropdown';
import { Popover } from 'antd';

export const inventoryColumns = [
  {
    title: 'Product name',
    dataIndex: 'productName',
    key: '1',
  },
  {
    title: 'SKU-ID',
    dataIndex: 'productId',
    key: '2',
  },
  {
    title: 'Specifications',
    dataIndex: 'specifications',
    key: '3',
    render: (value, record) => {
      const { threadcount, construction, width, loomType = 1 } = record || {};
      return (
        <>
          <div className={style['specification-column']}>
            <div>{threadcount}</div>
            <div className={style['dot']}></div>
            <div>{construction}</div>
          </div>
          <div className={style['specification-column']}>
            <div>{`${width} inch`}</div>

            <div className={style['dot']}></div>
            <div>{LOOM_TYPES_KEY[loomType]}</div>
          </div>
        </>
      );
    },
  },
  {
    title: 'Warehouse name',
    dataIndex: 'warehouseName',
    key: '4',
    render: (value, record) => {
      return (
        <div>
          {!record?.warehouseName
            ? '--'
            : `${record?.warehouseName || ''}, ${record?.warehouseLocation || ''}`}
        </div>
      );
    },
  },
  {
    title: 'Available Quantity',
    dataIndex: 'totalQuantityInWarehouse',
    key: '5',
    render: (value, record) => {
      return <div>{!value ? '--' : `${value} m`}</div>;
    },
  },
  {
    title: 'Details',
    dataIndex: 'details',
    key: '6',
    render: (value, record) => {
      return (
        <p
          onClick={() => {
            history.push(
              `/sessioner/inventory-management/details/?record=${JSON.stringify(record)}`,
              record,
            );
          }}
          className={style.viewStyles}>
          View
          <img src={Chevron} alt="" />
        </p>
      );
    },
  },
];

export const editInventoryDataSource = [
  {
    baleNo: '7654',
    quantity: '1000 m',
    docketNo: '12345',
    productName: 'Rayon plain',
    sourcingPrice: '₹ 86.67/m',
    supplierName: 'Locofast Online Services Pvt Ltd.',
    fulfilledBy: 'Locofast',
    creationDate: 'Aug 12, 2023',
  },
  {
    baleNo: '7654',
    quantity: '1000 m',
    docketNo: '12345',
    productName: 'Rayon plain',
    sourcingPrice: '₹ 86.67/m',
    supplierName: 'Aaradhaya Creations',
    fulfilledBy: 'Locofast',
    creationDate: 'Aug 12, 2023',
  },
  {
    baleNo: '7654',
    quantity: '1000 m',
    docketNo: '12345',
    productName: 'Rayon plain',
    sourcingPrice: '₹ 86.67/m',
    supplierName: 'Locofast Online Services Pvt Ltd.',
    fulfilledBy: 'Locofast',
    creationDate: 'Aug 12, 2023',
  },
  {
    baleNo: '7654',
    quantity: '1000 m',
    docketNo: '12345',
    productName: 'Rayon plain',
    sourcingPrice: '₹ 86.67/m',
    supplierName: 'Locofast Online Services Pvt Ltd.',
    fulfilledBy: 'Locofast',
    creationDate: 'Aug 12, 2023',
  },
  {
    baleNo: '7654',
    quantity: '1000 m',
    docketNo: '12345',
    productName: 'Rayon plain',
    sourcingPrice: '₹ 86.67/m',
    supplierName: 'Locofast Online Services Pvt Ltd.',
    fulfilledBy: 'Locofast',
    creationDate: 'Aug 12, 2023',
  },
];
export const initialValues = {
  fabricType: null,
  x: '',
  y: '',
  productCategory: null,
  epi: '',
  ppi: '',
  subCategory: [],
  requirementType: null,
  width: '',
  loomType: '',
  weight: '',
};

export const editInventoryColumns = ({
  setOpenDeleteModal,
  setOpenEditModal,
  setDiscardModal,
  handleStatusChange,
  tab,
}) => [
  {
    title: 'Bale no.',
    dataIndex: 'baleNumber',
    key: '1',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: '2',
    render: (value, record) => {
      const content = (
        <>
          <div className={style['pop-box']}>
            <div className={style.popHeading}>Net weight</div>
            <div>
              <span className={style.popValue}>{record.netWeight || '--'}</span>
              <span>kg</span>
            </div>
          </div>
          <div className={style.seperator} />
          <div className={style['pop-box']}>
            <div>Gross weight</div>
            <div>
              <span className={style.popValue}>{record.grossWeight || '--'}</span>
              <span>kg</span>
            </div>
          </div>
        </>
      );
      return (
        <div className={style.flex}>
          <div>{+value}</div>
          <Popover
            placement="top"
            style={{ color: 'red' }}
            content={content}
            arrowContent={{ 'background-color': 'red' }}
            trigger="click">
            <img style={{ marginLeft: 12 }} src={infoIcon} alt="info icon" />
          </Popover>
        </div>
      );
    },
  },
  {
    title: 'Docket no.',
    dataIndex: 'docketNumber',
    key: '3',
  },
  {
    title: 'Sourcing Price',
    dataIndex: 'sourcingPrice',
    key: '4',
  },
  {
    title: 'LFB-ID',
    dataIndex: 'generatedFabricId',
    tab: BALE_INVENTORY_TABS.AVAILABLE,
    key: '44',
    render: (value, record) => {
      return <div>{!value ? '--' : value}</div>;
    },
  },
  {
    title: 'Supplier name',
    dataIndex: 'supplierName',
    key: '5',
    render: (value, { isLcv }) => {
      return (
        <div className={style.flex}>
          {!!isLcv && <img style={{ marginRight: 8 }} src={certifiedTick} alt="certified tick" />}
          <div>{value}</div>
        </div>
      );
    },
  },
  {
    title: 'Fulfilled by',
    dataIndex: 'fulFilledByName',
    key: '6',
  },
  {
    title: 'Creation date',
    dataIndex: 'creationDate',
    tab: BALE_INVENTORY_TABS.ARCHIVED,
    key: '7',
  },
  {
    title: 'Last updated on',
    dataIndex: 'lastModifiedOn',
    tab: BALE_INVENTORY_TABS.AVAILABLE,
    key: '8',
    render: (value, record) => {
      return <div>{!value ? '--' : value}</div>;
    },
  },
  {
    title: '',
    dataIndex: '',
    tab: BALE_INVENTORY_TABS.ARCHIVED,
    render: (value, record) => {
      return (
        <div className="flex items-center">
          <div
            className={style.edit}
            onClick={() => {
              setDiscardModal({ visibility: true, record });
            }}>
            Discard
          </div>
          <div
            className={style.editBtn}
            onClick={() => {
              setOpenEditModal({ visibility: true, record });
            }}>
            <img src={editIcon} alt="edit icon" />
          </div>
          <div
            className={style.pointer}
            onClick={() => {
              setOpenDeleteModal({ visibility: true, record });
            }}>
            <img src={trashIcon} alt="trash icon" />
          </div>
        </div>
      );
    },
  },
  {
    title: 'Status',
    tab: BALE_INVENTORY_TABS.AVAILABLE,
    dataIndex: '',
    render: (value, record) => {
      return <StatusDropdown handleStatusChange={handleStatusChange} record={record} />;
    },
  },
];
export const MIN_WIDTH = 20;
export const MAX_WIDTH = 200;
export const QUERY_CATEGORY = {
  1: 'Cotton',
  2: 'Rayon',
  3: 'Polyester',
  4: 'Blends',
  5: 'Denim',
};

export const BALE_INVENTORY_TABS = {
  AVAILABLE: 10,
  ARCHIVED: 20,
};

export const BALES_STATUS = {
  AVAILABLE: '10',
  READY_FOR_PICKUP: '20',
  IN_TRANSIT: '30',
  DELIVERED: '40',
  DISCARD: '50',
  CANCELLED: '60',
};

export const BALES_STATUS_REVERSE = {
  [BALES_STATUS.AVAILABLE]: 'Available',
  [BALES_STATUS.READY_FOR_PICKUP]: 'Ready for pickup',
  [BALES_STATUS.IN_TRANSIT]: 'In transit',
  [BALES_STATUS.DELIVERED]: 'Delivered',
  [BALES_STATUS.DISCARD]: 'Discarded',
  [BALES_STATUS.CANCELLED]: 'Cancelled',
};

export const BALE_STATUS_DROPDOWN_MENU_OPTIONS = [
  {
    label: BALES_STATUS_REVERSE[BALES_STATUS.AVAILABLE],
    key: BALES_STATUS.AVAILABLE,
  },
  // {
  //   label: BALES_STATUS_REVERSE[BALES_STATUS.READY_FOR_PICKUP],
  //   key: BALES_STATUS.READY_FOR_PICKUP,
  // },
  // {
  //   label: BALES_STATUS_REVERSE[BALES_STATUS.IN_TRANSIT],
  //   key: BALES_STATUS.IN_TRANSIT,
  // },
  // {
  //   label: BALES_STATUS_REVERSE[BALES_STATUS.DELIVERED],
  //   key: BALES_STATUS.DELIVERED,
  // },
  // {
  //   label: BALES_STATUS_REVERSE[BALES_STATUS.DISCARD],
  //   key: BALES_STATUS.DISCARD,
  // },
];

// TODO Make more dynamic
export const FULFILLED_BY = {
  Locofast: '1',
  Supplier: '2',
};

export const FULFILLED_BY_OPTIONS = [
  { label: 'Locofast', value: '1' },
  { label: 'Supplier', value: '2' },
];
