import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Button, Modal, Table } from 'antd';
import { inventoryColumns } from './base';
import InventoryManagementForm from './InventoryManagementForm';
import FileUpload from 'components/HOCs/FileUpload';
import { connect } from 'react-redux';
import { getMaterialData } from 'store/actions/FabricQualitiesActions';
import {
  getInventoryData,
  getWarehouseIdData,
  uploadInventory,
} from 'store/actions/InventoryAction';

const InventoryManagement = ({
  getMaterialDataApi,
  getTableData,
  tableData,
  getWarehouseFILE,
  uploadInventory,
}) => {
  const [openInventoryForm, setOpenInventoryForm] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [inventoryFileUpload, setInventoryFileUpload] = useState('');
  const [wareHouseId, setWarehouseId] = useState('');

  const DEFAULT_DATA_LIMIT = 10;

  useEffect(() => {
    getMaterialDataApi();
  }, []);

  useEffect(() => {
    // Pagination will add in future
    let params = {
      // pageNumber: page,
      // limit: DEFAULT_DATA_LIMIT,
    };
    getTableData(params);
  }, [page, loader]);

  const reloader = () => {
    setLoader(!loader);
  };

  const handleUploadClick = () => {
    setOpenUploadModal(true);
    getWarehouseFILE().then((resp) => {
      setWarehouseId(resp?.data?.data);
    });
  };
  const onUploadInventory = () => {
    let data = new FormData();
    data.append('file', inventoryFileUpload);
    uploadInventory(data).then(() => {
      setOpenUploadModal(false);
      reloader();
      setInventoryFileUpload('');
    });
  };
  return (
    <div className={styles.page}>
      <div className={styles.head}>
        <div className={styles.headline}>Inventory management</div>
        <div>
          <button className={styles.skuBtn} onClick={() => setOpenInventoryForm(true)}>
            Create SKU
          </button>
          <button className="btn btn--blue-bg " onClick={() => handleUploadClick()}>
            Upload inventory
          </button>
        </div>
      </div>
      <div className="relative">
        <div className={styles['table-count']}>Total results: {tableData.length}</div>
      </div>

      <Table
        className={styles.inventoryTable}
        columns={inventoryColumns}
        dataSource={tableData}
        pagination={false}
        // pagination={{
        //   position: 'bottom',
        //   defaultPageSize: DEFAULT_DATA_LIMIT,
        //   total: tableData?.length,
        //   size: 'small',
        //   showSizeChanger: false,
        //   onChange: (page) => {
        //     setPage(Number(page));
        //   },
        // }}
      />

      <InventoryManagementForm
        visible={openInventoryForm}
        onClose={() => setOpenInventoryForm(false)}
        reload={reloader}
      />
      <Modal
        title="Upload inventory"
        open={openUploadModal}
        onCancel={() => setOpenUploadModal(false)}
        className="suppliers-modal"
        destroyOnClose
        footer={[
          <Button className={styles.clear} onClick={() => setOpenUploadModal(false)}>
            Close
          </Button>,
          <Button className={styles.apply} onClick={() => onUploadInventory()}>
            Submit
          </Button>,
        ]}>
        <>
          <div>
            <p className={styles.uploadTxt}>Upload CSV file</p>
          </div>
          <FileUpload file={inventoryFileUpload} setFile={setInventoryFileUpload} accept={'.csv'} />
          <div className={styles.downloadContainer}>
            <a
              className={styles.downloadAnchor}
              href={process.env.REACT_APP_INVENTORY_SAMPLE_FORMAT || ''}>
              Download sample format
            </a>
            <a className={styles.downloadAnchor} href={wareHouseId || ''}>
              Download warehouse ID
            </a>
          </div>
        </>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state, props) => ({
  materialData: state.fabricQualities.materialData,
  tableData: state.inventoryData.inventoryData,
});

const mapDispatchToProps = (dispatch) => ({
  getMaterialDataApi: () => dispatch(getMaterialData({ isSession: true })),
  getTableData: (data) => dispatch(getInventoryData(data)),
  getWarehouseFILE: () => dispatch(getWarehouseIdData()),
  uploadInventory: (data) => dispatch(uploadInventory(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InventoryManagement);
