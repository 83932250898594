import { Col, Drawer, Row } from 'antd';
import RadioField from 'components/AccountSettings/TopQualities/RadioField';
import {
  CATEGORY_TYPES,
  PRODUCT_TYPES,
  REQUIREMENT_TYPES_TABS,
} from 'components/AccountSettings/TopQualities/config';
import { Form, Formik } from 'formik';
import React from 'react';
import styles from '../styles.module.scss';
import { getMaterialData } from 'store/actions/FabricQualitiesActions';
import { connect } from 'react-redux';
import Select from 'components/AccountSettings/TopQualities/Select';
import FieldInput from 'components/AccountSettings/TopQualities/FieldInput';
import Status from 'components/AccountSettings/TopQualities/status';
import { MAX_WIDTH, MIN_WIDTH, QUERY_CATEGORY, initialValues } from '../base';
import * as Yup from 'yup';
import { createNewSKU } from 'store/actions/InventoryAction';

const InventoryManagementForm = ({ visible, onClose, materialData, createNewSKU, reload }) => {
  const validationSchema = Yup.object().shape(
    {
      fabricType: Yup.number().required('Please select any one option'),
      loomType: Yup.number().required('Please select any one option'),
      width: Yup.number().positive().max(150, 'Maximum value 150').required('Required'),
      weight: Yup.number().min(0.1).max(3000, 'Maximum value 3000').required('Required'),
      epi: Yup.number()
        .positive()
        .min(20, 'Minimum value 20')
        .max(200, 'Maximum value 200')
        .required('Required'),

      ppi: Yup.number()
        .positive()
        .min(20, 'Minimum value 20')
        .max(200, 'Maximum value 200')
        .required('Required'),

      x: Yup.string()
        .matches(/^(\d+)(\s?\/\s?\d+)?$/, 'Format: 30 or 2 / 30')
        .max(4, 'Please enter upto 4 characters')
        .required('Required'),

      y: Yup.string()
        .matches(/^(\d+)(\s?\/\s?\d+)?$/, 'Format: 30 or 3 / 30')
        .max(4, 'Please enter upto 4 characters')
        .required('Required'),

      requirementType: Yup.string().required('Please select any one option'),
      subCategory: Yup.string().required('Please select any one option'),
    },
    [
      ['x', 'y'],
      ['epi', 'ppi'],
    ],
  );
  const onSubmitForm = (values) => {
    const subcategory = materialData.productSubCategory[values.productCategory].find(
      (item) => item.subCategoryId === +values.subCategory,
    );
    const form = {
      name: `${QUERY_CATEGORY[values.productCategory]} ${subcategory.subCategoryName}`,
      fabricType: values.fabricType,
      loomType: values.loomType,
      threadcount: JSON.stringify({
        x: values.x,
        y: values.y,
      }),
      construction: JSON.stringify({
        epi: values.epi,
        ppi: values.ppi,
      }),
      width: values?.width,
      weight: Number(values?.weight),
      weightUnitId: 10,
      productionSubType: 1, //griege
      productionType: 1, // weaving
      unit: 2,
      categoryComposition: JSON.stringify([
        {
          categoryId: values.subCategory,
          percentage: '100',
        },
      ]),
    };
    createNewSKU(form).then((res) => {
      reload();
      onClose();
    });
  };

  return (
    <Drawer
      destroyOnClose
      open={visible}
      title={'Create SKU'}
      onClose={() => {
        onClose();
      }}
      width={700}
      bodyStyle={{ paddingBottom: 80 }}>
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSubmitForm(values);
        }}>
        {({ errors, dirty, values }) => {
          const widthError = values?.widths
            ?.map(({ value }) => +value)
            .some((element) => element < MIN_WIDTH || element > MAX_WIDTH);
          const disableCondition =
            !dirty || (dirty && Object.keys(errors).length > 0) || widthError;
          return (
            <Form>
              <Row className={styles['form-row']} gutter={36}>
                <Col className={styles['form-column']}>
                  <RadioField
                    isRequired={true}
                    name="fabricType"
                    label="Fabric type"
                    options={PRODUCT_TYPES}
                  />
                </Col>
                <Col className={styles['form-column']}>
                  <RadioField
                    isRequired={true}
                    name="requirementType"
                    label="Requirement type"
                    options={REQUIREMENT_TYPES_TABS}
                  />
                </Col>
              </Row>
              <Row className={styles['form-row']} gutter={36}>
                <Col className={styles['form-column']}>
                  <RadioField
                    isRequired={true}
                    name="productCategory"
                    label="Category"
                    options={CATEGORY_TYPES}
                  />
                </Col>
                <Col className={styles['form-column']} span={12}>
                  <Select
                    isRequired
                    isGlobal
                    fieldKey="subCategoryId"
                    fieldValue="subCategoryName"
                    name="subCategory"
                    label="Sub-Category"
                    placeholder="Select Sub-Category"
                    options={materialData.productSubCategory[String(values?.productCategory)]}
                  />
                </Col>
              </Row>
              <Row className={styles['form-row']} gutter={36}>
                <Col className={styles['form-column']} span={12}>
                  <div>
                    <p className="flex justify-between">
                      <span className={styles.name}>Thread Count</span>
                      <Status
                        isFilled={
                          !errors?.x && !!values?.x?.length && !errors?.y && !!values?.y?.length
                        }
                      />
                    </p>
                    <div className="flex">
                      <FieldInput name="x" placeholder="e.g. 120" type="text" />
                      <div className={styles['cross']}>X</div>
                      <FieldInput name="y" placeholder="e.g. 120" type="text" />
                    </div>
                  </div>
                </Col>
                <Col className={styles['form-column']} span={12}>
                  <div>
                    <p className="flex justify-between">
                      <span className={styles.name}>Construction</span>
                      <Status
                        isFilled={!errors?.epi && !!values?.epi && !errors?.ppi && !!values?.ppi}
                      />
                    </p>
                    <div className="flex">
                      <FieldInput name="epi" placeholder="EPI" type="number" />
                      <div className={styles['cross']}>X</div>
                      <FieldInput name="ppi" placeholder="PPI" type="number" />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className={styles['form-row']} gutter={36}>
                <Col className={styles['form-column']} span={12}>
                  <FieldInput
                    isRequired={true}
                    name="width"
                    label="Width"
                    placeholder="Enter Width"
                    type="number"
                    isUnit="inch"
                  />
                </Col>
                <Col className={styles['form-column']} span={12}>
                  <FieldInput
                    isRequired
                    name="weight"
                    label="GSM"
                    placeholder="Enter Weight"
                    type="number"
                    isUnit="GSM"
                  />
                </Col>
              </Row>
              <Row className={styles['form-row']} gutter={36}>
                <Col className={styles['form-column']} span={12}>
                  <RadioField
                    isRequired={true}
                    name="loomType"
                    label="Loom type"
                    options={materialData.loomType}
                    isUpdate={false}
                  />
                </Col>
              </Row>
              <Row className={styles['form-row']} gutter={10}>
                <Col className={styles['form-column']} span={12}></Col>
                <Col className={styles['form-column']} span={12}>
                  <div className={styles['form-button']}>
                    <button
                      type="submit"
                      disabled={disableCondition}
                      className={disableCondition ? styles.saveDisabled : styles.save}
                      style={false ? { width: '134px' } : { width: '100%' }}>
                      Create
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Drawer>
  );
};
const mapStateToProps = (state) => ({
  materialData: state.fabricQualities.materialData,
});
const mapDispatchToProps = (dispatch) => ({
  getMaterialData: () => dispatch(getMaterialData()),
  createNewSKU: (data) => dispatch(createNewSKU(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryManagementForm);
